import React, { useState, useEffect, useContext } from "react";

import { fetchAuthSession } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

import { createContext } from "react";
import {
  FundDashboardPage,
  MyFundsPageUrl,
} from "../constants/pages";
import { Account, USER_ROLE } from "types/account";
import { AuthData, UserData } from "types/auth";
import { User } from "types/user";
import { FundAccountMember } from "types/fund-account-member";
import apiHttpClient from "libs/api-http-client";
import { useGetUserQuery } from "hooks/use-services/user.service";
import { useIdleTimer } from "react-idle-timer";
import mixpanel from "mixpanel-browser";
import awsExports from "aws-exports";
import { NetaSetting } from "constants/neta-settings";
import { NetaSettingContext } from "./netaSettingsProvider";
//TODO : set the token on env vars
const MIXPANEL_TOKEN_DEV = 'd699b6449eda50f9aa96b766661a65ce';
const MIXPANEL_TOKEN_STAGING = '94ce3f8fd079c96fecff2146714889aa';
const MIXPANEL_TOKEN_PROD = '2ecd6466231d9b22502ddd4c34a8d43b';

const MIXPANEL_TOKEN = (awsExports.endpoints[0].endpoint.includes('prod')) ? MIXPANEL_TOKEN_PROD :
  (awsExports.endpoints[0].endpoint.includes('staging')) ? MIXPANEL_TOKEN_STAGING : MIXPANEL_TOKEN_DEV;

export const AuthContext = createContext<AuthData>({
  userData: {
    user: null,
    email: "",
    sub: "",
  },
  userRole: USER_ROLE.ADMIN,
  selectedAccount: null,
  refresh: false,
  setRefresh: (value: boolean) => { },
  setUserData: () => { },
  setUserRole: () => { },
  setSelectedAccount: (account: Account) => { },
  isAccountRejected: false,
  setIsAccountRejected: (value: boolean) => { },
});

interface props {
  children?: React.ReactNode;
  api: any;
}
const UserContextProvider = ({ children }: props) => {
  const { mixpanelReplay, mixpanelSessionPercent } = useContext<NetaSetting>(NetaSettingContext);
  const { route, signOut } = useAuthenticator((context) => [context.route]);
  const [isAccountRejected, setIsAccountRejected] = React.useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData>({
    user: null,
    sub: null,
    email: null,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [userRole, setUserRole] = useState<USER_ROLE | null>(null);

  const value = {
    userData,
    setUserData,
    selectedAccount,
    setSelectedAccount,
    setRefresh,
    refresh,
    isAccountRejected,
    setIsAccountRejected,
    userRole,
    setUserRole,
  };
  const handleOnIdle = () => {
    signOut()
  };


  useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 minutes
    onIdle: handleOnIdle,
    debounce: 500,
  });
  const setUser = (user: User | undefined) => {
    if (user) {
      if (user?.userId === undefined) {
        navigate(`/signup/${userData.sub}`, {
          state: { sub: userData.sub, email: userData.email },
        });
        signOut();
      } else {
        if (user.status === "REJECTED") {
          setIsAccountRejected(true);
          signOut();
        } else {
          console.log(mixpanelReplay, mixpanelSessionPercent)
          if (mixpanelReplay) {
            mixpanel.init(MIXPANEL_TOKEN, {
              record_sessions_percent: mixpanelSessionPercent,
              persistence: "localStorage",
            });
          }
          else {
            mixpanel.init(MIXPANEL_TOKEN, {
              persistence: "localStorage",
            });
          }

          mixpanel.identify(user.sub)
          mixpanel.people.set({
            $first_name: user.firstName,
            $last_name: user.lastName,
            $email: user.email,
          })
          if (mixpanelReplay) {
            mixpanel.start_session_recording()
          }

          mixpanel.track('UserSignedIn', {
            user: `${user.firstName} ${user.lastName}`,
            email: user.email
          });
          setUserData({
            ...userData,
            user: user,
          });
        }
      }
    }
  };

  const { data, refetch } = useGetUserQuery(userData.sub!);
  async function checkUserAuth() {
    if (!userData.sub) {
      try {
        const { idToken } = (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
        const result = idToken?.payload;

        apiHttpClient.defaults.headers.common['Authorization'] = 'Bearer ' + idToken?.toString()
        if (result) {
          setUserData({
            sub: result.sub!,
            email: result.email!,
          });
        }

        //getSession()
        if (process.env.NODE_ENV !== "production") {
          // eslint-disable-next-line no-console
          console.log(userData);
        }
      } catch {
        signOut();
        setUserData({
          user: null,
          sub: null,
          email: null,
        });
      }
    }
  }
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    setUser(data)
    if (data && data?.status !== "REJECTED" && data?.fundAccounts) {
      const loggedIn = localStorage.getItem("loggedIn");
      if (loggedIn !== "true") {
        localStorage.setItem("loggedIn", "true");
        if (data.fundAccounts?.length === 1) {
          navigate(FundDashboardPage);
        } else {
          navigate(MyFundsPageUrl);
        }
      }
      const fundAccountId = localStorage.getItem("selectedAccount");
      if (fundAccountId) {
        const fundAccountMember = data?.fundAccounts.find(
          (item: FundAccountMember) =>
            item.fund.fundAccountId === +fundAccountId
        );
        setUserRole(
          fundAccountMember
            ? fundAccountMember.role
            : data?.fundAccounts[0].role
        );
        setSelectedAccount(
          fundAccountMember
            ? fundAccountMember.fund
            : data?.fundAccounts[0].fund
        );
      } else {
        localStorage.setItem(
          "selectedAccount",
          data?.fundAccounts[0]?.fund?.fundAccountId?.toString() || "0"
        );
        setUserRole(data?.fundAccounts[0].role);
        setSelectedAccount(data.fundAccounts[0].fund);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    switch (route) {
      case "authenticated":
        checkUserAuth();
        break;
      case "signOut":
        localStorage.clear();
        setUserData({
          user: null,
          sub: null,
          email: null,
        });
        mixpanel.stop_session_recording()
        mixpanel.reset()
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default UserContextProvider;
